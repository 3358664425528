const cardsList = [
  {
    id: "test1",
    title: "Card Title",
    description: "Some description",
    linkText: "Link",
    link: "",
  },
  {
    id: "test2",
    title: "Card Title",
    description: "Some description",
    linkText: "Link",
    link: "",
  },
  {
    id: "test3",
    title: "Card Title",
    description: "Some description",
    linkText: "Link",
    link: "",
  },
  {
    id: "test4",
    title: "Card Title",
    description: "Some description",
    linkText: "Link",
    link: "",
  },
  {
    id: "test5",
    title: "Card Title",
    description: "Some description",
    linkText: "Link",
    link: "",
  },
];

export default cardsList;
